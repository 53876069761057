import React from "react"
import {
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share"
import LinkedInIcon from "../assets/svg/linkedin-brands.svg"
import TwitterIcon from "../assets/svg/twitter-square-brands.svg"
import FacebookIcon from "../assets/svg/facebook-square-brands.svg"
import RedditIcon from "../assets/svg/reddit-brands.svg"

const btnStyle = "h-full w-6 mx-2 opacity-60 hover:opacity-100 "

export default function ShareBar({ title, description, url }) {
  return (
    <div className="flex h-8 ">
      <LinkedinShareButton
        title={title}
        summary={description}
        source="https://github.com"
        url={url}
      >
        <LinkedInIcon className={btnStyle} />
      </LinkedinShareButton>
      <TwitterShareButton title={title} url={url} related={["markobilal"]}>
        <TwitterIcon className={btnStyle} />
      </TwitterShareButton>

      <FacebookShareButton url={url} title={title} description={description}>
        <FacebookIcon className={btnStyle} />
      </FacebookShareButton>

      <RedditShareButton url={url} title={title} description={description}>
        <RedditIcon className={btnStyle} />
      </RedditShareButton>
    </div>
  )
}
