import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareBar from "../components/share-bar"
import { useRef } from "react"
import { useEffect } from "react"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const {
    site: { siteMetadata },
  } = data

  const {
    fields: { readingTime },
  } = post

  // const image = post.frontmatter.image
  //   ? post.frontmatter.image.childImageSharp.resize
  //   : null

  const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.fluid
    : null

  const siteTitle = siteMetadata?.title || `Title`
  const { previous, next } = data

  const { title, description } = post.frontmatter

  const dref = useRef()
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://exceptional-teacher-3955.ck.page/bec1e004df/index.js" // convertkit

    script.async = true
    script.setAttribute("data-uid", "bec1e004df")
    dref.current.appendChild(script)
  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={title}
        description={description || post.excerpt}
        image={image}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="flex flex-col mb-0 items-center">
          <div>
            <h2 className="font-hs text-4xl leading-10 mb-4 mt-0 tracking-wider">
              {title}
            </h2>
            <p className="mb-0">{description}</p>
          </div>
          <div className="w-96 h-96 ">
            <Img fluid={image} alt="illustration" />
          </div>
        </header>
        <div className="flex justify-between items-baseline my-8">
          <p className="text-sm text-gray-500 mb-0">
            Date published: {post.frontmatter.date} ~{readingTime.text}
          </p>
          <ShareBar
            title={title}
            description={description}
            url={`${siteMetadata.siteUrl}${location.pathname}`}
          />
        </div>
        {/* <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header> */}
        <MDXRenderer className="text-xl">{post.body}</MDXRenderer>

        <hr />
      </article>

      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          {previous && (
            <li>
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            </li>
          )}

          {next && (
            <li>
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            </li>
          )}
        </ul>
      </nav>
      <div ref={dref} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        image: featured {
          childImageSharp {
            fluid(maxHeight: 384) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        description
      }
      fields {
        readingTime {
          text
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
